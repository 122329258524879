/* This is only using the positive fonts */

@font-face {
  font-family: 'FSMillbankWeb-Regular';
  src: local('FSMillbankWeb-Regular'),
    url('./FSMillbankWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FSMillbankWeb-Italic';
  src: local('FSMillbankWeb-Italic'),
    url('./FSMillbankWeb-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'FSMillbankWeb-Light';
  src: local('FSMillbankWeb-Light'),
    url('./FSMillbankWeb-Light.woff') format('woff');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'FSMillbankWeb-LightItalic';
  src: local('FSMillbankWeb-LightItalic'),
    url('./FSMillbankWeb-LightItalic.woff') format('woff');
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: 'FSMillbankWeb-Bold';
  src: local('FSMillbankWeb-Bold'),
    url('./FSMillbankWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FSMillbankWeb-BoldItalic';
  src: local('FSMillbankWeb-BoldItalic'),
    url('./FSMillbankWeb-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'FSMillbankWeb-Heavy';
  src: local('FSMillbankWeb-Heavy'),
    url('./FSMillbankWeb-Heavy.woff') format('woff');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'FSMillbankWeb-HeavyItalic';
  src: local('FSMillbankWeb-HeavyItalic'),
    url('./FSMillbankWeb-HeavyItalic.woff') format('woff');
  font-weight: bolder;
  font-style: italic;
}
